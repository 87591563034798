import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '../components/images/Sprits/icons';
import '../modules/Nquestionable/questionable';
import '../assets/global-style/style.scss';
import Cookie from '../modules/Сookie/Сookie';
import {sendEvent, setConfig, setMeta, setProfile } from '@sbol/clickstream-agent';

(function () {
    const $cookie = document.querySelector('.js-ns-cookie');

    if ($cookie) {
        new Cookie($cookie);
	}

	let hash = window.location.hash;

	setConfig({
		reportUrl: 'https://ext.clickstream.sberbank.ru/metrics/ecosystem/sberinsur'
		// остальные параметры опциональны, см. официальную документацию
	});

// Установка параметров приложения и устройства
	setMeta({
		apiKey: 'b960b139f9bd987cde8f63bf5f78c37522f4993888735076ed1dcfd9299810ce'
		// остальные параметры опциональны, см. официальную документацию
	});

	setProfile({
		hashUserLoginId: hash,
		appVersion: '1.0.0'
	});
	
	console.log('global init');
    console.log('test init');
})();
